import { useState, useEffect } from 'react'

import { useExperiment } from 'rvbr-www/red-testing'

import { NAVIGATION_LINKS, CONSUMER_LINKS } from '../../constants'

export const useNavigationLinks = () => {
  const [headerNavigationLinks, setHeaderNavigationLinks] = useState()

  const { variant } = useExperiment('form_v2')

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

  const setupNavigationLinks = () => {
    const isPostpaidInV2 = !isOrganic && variant.id === 'b'

    const businessLinks = [...NAVIGATION_LINKS]

    if (isPostpaidInV2) {
      businessLinks.unshift({
        id: 'plans',
        label: 'Planos Pós-Pago',
        path: '#planos'
      })
    }

    const navigationLinks = {
      consumer: CONSUMER_LINKS,
      business: businessLinks
    }

    setHeaderNavigationLinks(navigationLinks)
  }

  useEffect(() => {
    setupNavigationLinks()
  }, [])

  return {
    headerNavigationLinks
  }
}
