'use client'

import { useState } from 'react'

import { usePlans } from '@/hooks/usePlans'

import { If, NewModal, PaymentExample, Plan } from '@/components'

import styles from './PlanList.module.scss'

export const PlanList = ({
  children,
  selectedPlan,
  setSelectedPlan,
  onClickCTA
}) => {
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)
  const [expandedPlans, setExpandedPlans] = useState({})

  const { postpaidPlans } = usePlans()

  return (
    <>
      <If condition={isPaymentDetailsOpen}>
        <NewModal
          customClass={styles.plans__paymentDetails}
          onClose={() => setIsPaymentDetailsOpen(false)}
        >
          <PaymentExample />
        </NewModal>
      </If>

      <div className={styles.plans}>
        {postpaidPlans.map((plan) => (
          <Plan
            plan={plan}
            key={`plans-${plan.id}`}
            selectedPlan={selectedPlan || 'advanced'}
            customClass={styles.plans__card}
            expandedPlans={expandedPlans}
            setExpandedPlans={setExpandedPlans}
            openPaymentDetails={() => setIsPaymentDetailsOpen(true)}
            onClickCard={setSelectedPlan}
            onClickCTA={onClickCTA}
          >
            {children}
          </Plan>
        ))}
      </div>

      <If condition={isPaymentDetailsOpen}>
        <NewModal
          customClass={styles.plans__paymentDetails}
          onClose={() => setIsPaymentDetailsOpen(false)}
        >
          <PaymentExample />
        </NewModal>
      </If>
    </>
  )
}
