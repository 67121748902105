import styles from './ProgressBar.module.scss'

export const ProgressBar = ({ value }) => {
  return (
    <div className={styles.progressbar}>
      <label htmlFor="hiring" className={styles.progressbar__label}>
        Contratação em andamento
      </label>

      <progress
        id="hiring"
        max="100"
        value={value}
        className={styles.progressbar__bar}
      />
    </div>
  )
}
