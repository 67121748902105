import { useScreenSize } from '@/hooks/useScreenSize'

import { Container, Divider } from '@/components'

import { NavigationButtons, NavigationLinks, WorldwideSitesToggler } from '..'

import styles from './Menu.module.scss'

export const Menu = ({ setIsWorldwideSitesVisible, closeMenu }) => {
  const { windowHeight } = useScreenSize()

  return (
    <Container
      data-overlay={true}
      classname={styles.menu}
      style={{
        height: windowHeight
      }}
    >
      <div>
        <nav className={styles.menu__content}>
          <p className={styles.menu__category}>Para sua empresa</p>

          <NavigationLinks
            variant="menu"
            category="business"
            onClick={closeMenu}
          />

          <Divider />

          <p className={styles.menu__category}>Para você</p>

          <NavigationLinks
            variant="menu"
            category="consumer"
            onClick={closeMenu}
          />

          <Divider />
        </nav>

        <WorldwideSitesToggler
          arrowPosition="right"
          onClick={() => setIsWorldwideSitesVisible((prev) => !prev)}
        />
      </div>

      <NavigationButtons closeMenu={closeMenu} />
    </Container>
  )
}
