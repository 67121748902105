import styles from './SkeletonLoader.module.scss'

const Single = ({ customClass, height, top = 0 }) => {
  return (
    <div className={`${styles.skeletonLoader} ${customClass}`}>
      <div
        className={styles.skeletonLoader__single}
        style={height && { height: `${height}px`, marginTop: `${top}px` }}
      />
    </div>
  )
}

const Step = ({ customClass }) => {
  return (
    <div id="step-loader" className={`${styles.skeletonLoader} ${customClass}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

const SkeletonLoader = { Single, Step }

export { SkeletonLoader }
