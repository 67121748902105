/* eslint-disable no-undef */

import { KONDUTO_URL, REPRESENTATIVE_URL } from '@/constants/links'

import {
  CALLCENTER_PHONE_NUMBER,
  POSITIVE_SUBSCRIPTION_PHONE_NUMBER,
  SAC_PHONE_NUMBER
} from '@/constants/phones'

export const LINKS = [
  {
    category: 'Equifax | BoaVista',
    links: [
      {
        id: 'about-link',
        url: `${urlEquifaxOrganic}/sobre-a-boa-vista-scpc/`,
        text: 'Sobre a Equifax | BoaVista'
      },
      {
        id: 'terms-privacy-link',
        url: `${urlEquifaxOrganic}/termo-de-uso-e-politica-de-privacidade/`,
        text: 'Termos de Uso e Politica de Privacidade'
      },
      {
        id: 'right-link',
        url: `${urlEquifaxOrganic}/direito-de-arrependimento/`,
        text: 'Direito de Arrependimento'
      },
      {
        id: 'blog-link',
        url: `${urlEquifaxOrganic}/blog/`,
        text: 'Blog Equifax | BoaVista'
      }
    ]
  },
  {
    category: 'Grupo Equifax | BoaVista',
    links: [
      {
        id: 'konduto-link',
        target: '_blank',
        url: KONDUTO_URL,
        text: 'Konduto'
      }
    ]
  },
  {
    category: 'Institucional',
    links: [
      {
        id: 'press-link',
        url: `${urlEquifaxOrganic}/sala-de-imprensa/`,
        text: 'Sala de imprensa'
      },
      {
        id: 'representative-link',
        target: '_blank',
        url: REPRESENTATIVE_URL,
        text: 'Seja um representante'
      },
      {
        id: 'positive-font-link',
        url: `${urlEquifaxOrganic}/fontes/`,
        text: 'Seja uma fonte positiva'
      }
    ]
  },
  {
    category: 'Informe-se',
    links: [
      {
        id: 'lgpd-link',
        url: `${urlEquifaxOrganic}/protecao-de-dados/`,
        text: 'LGPD'
      },
      {
        id: 'customer-service-link',
        url: `${urlEquifaxOrganic}/ouvidoria/`,
        text: 'Ouvidoria'
      },
      {
        id: 'payment-link',
        target: '_blank',
        url: 'https://web2.bvsnet.com.br/transacional/login.php?tp_acesso=SUAFATURA',
        text: '2ª via de boleto'
      }
    ]
  },
  {
    category: 'Para você',
    links: [
      {
        id: 'cpf-consultation-link',
        target: '_blank',
        url: 'https://consumidor.boavistaservicos.com.br/consulta-cpf-gratis/',
        text: 'Consulta de CPF'
      },
      {
        id: 'positive-registration-link',
        target: '_blank',
        url: 'https://consumidor.boavistaservicos.com.br/cadastro-positivo/',
        text: 'Cadastro Positivo'
      },
      {
        id: 'contact-link',
        target: '_blank',
        url: 'https://consumidor.boavistaservicos.com.br/fale-conosco/',
        text: 'Fale conosco'
      }
    ]
  }
]

export const CONTACT_NUMBERS = [
  {
    label: 'Atendimento Empresas',
    phone_number: SAC_PHONE_NUMBER
  },
  {
    label: 'Central de Vendas',
    phone_number: CALLCENTER_PHONE_NUMBER
  },
  {
    label: 'Cadastro Positivo',
    phone_number: POSITIVE_SUBSCRIPTION_PHONE_NUMBER
  }
]
