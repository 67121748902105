import { useEffect, useState } from 'react'

import isClient from '@equifax-ui/utils/browser/isClient'

const HEADER_MOBILE_HEIGHT = 64

export const useScreenSize = () => {
  const [screenHeight, setScreenHeight] = useState(HEADER_MOBILE_HEIGHT)

  const windowHeight = `${screenHeight - HEADER_MOBILE_HEIGHT}px`

  const setScreenSize = () => {
    if (isClient() && window?.innerHeight > 0) {
      setScreenHeight(window.innerHeight)
    }
  }

  useEffect(() => {
    setScreenSize()
  }, [])

  return {
    windowHeight
  }
}
