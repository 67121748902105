import classnames from 'classnames'
import styles from './Container.module.scss'

export const Container = ({ children, classname, ...props }) => {
  return (
    <div
      className={classnames(styles.container, { [classname]: classname })}
      {...props}
    >
      {children}
    </div>
  )
}
