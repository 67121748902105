import { CLIENT_PORTAL_URL } from '@/constants/links'

export const BUTTONS_DATA = {
  paid: [
    {
      target: '_blank',
      rel: 'noreferrer',
      id: 'header-login-button',
      text: 'Entrar',
      icon: 'equifax-user',
      variant: 'secondary',
      url: CLIENT_PORTAL_URL
    }
  ],
  organic: [
    { id: 'header-help-button', text: 'Ajuda', variant: 'link', url: '#ajuda' },
    {
      target: '_blank',
      rel: 'noreferrer',
      id: 'header-login-button',
      text: 'Entrar',
      icon: 'equifax-user',
      variant: 'secondary',
      url: CLIENT_PORTAL_URL
    }
  ]
}
