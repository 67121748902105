import { useState, useEffect } from 'react'

export const useLPHeader = () => {
  const [selectedCategory, setSelectedCategory] = useState('business')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isWorldwideSitesVisible, setIsWorldwideSitesVisible] = useState(false)

  useEffect(() => {
    if (!isMenuOpen) setIsWorldwideSitesVisible(false)
  }, [isMenuOpen])

  const closeMenu = () => {
    setIsMenuOpen(false)
    setIsWorldwideSitesVisible(false)
  }

  return {
    selectedCategory,
    isMenuOpen,
    isWorldwideSitesVisible,
    setSelectedCategory,
    setIsMenuOpen,
    setIsWorldwideSitesVisible,
    closeMenu
  }
}
