export const WORLDWIDE_SITES = [
  {
    title: 'Américas',
    list: [
      {
        text: 'Brasil',
        languages: [
          {
            label: 'Português',
            id: 'brasil_portugues',
            url: 'https://www.equifax.com.br/'
          }
        ]
      },
      {
        text: 'USA',
        languages: [
          {
            label: 'Inglês',
            id: 'usa_ingles',
            url: 'https://www.equifax.com'
          }
        ]
      },
      {
        text: 'Canadá',
        languages: [
          {
            label: 'Inglês',
            id: 'canada_ingles',
            url: 'https://www.consumer.equifax.ca/en/personal'
          },
          {
            label: 'Francês',
            id: 'canada_frances',
            url: 'https://www.consumer.equifax.ca/fr/personnel'
          }
        ]
      },
      {
        text: 'México',
        languages: [
          {
            label: 'Espanhol',
            id: 'mexico_espanhol',
            url: 'https://www.equifax.com.mx/es/empresa'
          },
          {
            label: 'Inglês',
            id: 'mexico_ingles',
            url: 'https://www.equifax.com.mx/en/business'
          }
        ]
      },
      {
        text: 'Argentina',
        languages: [
          {
            label: 'Espanhol',
            id: 'argentina_espanhol',
            url: 'https://www.soluciones.equifax.com.ar/'
          }
        ]
      },
      {
        text: 'Peru',
        languages: [
          {
            label: 'Espanhol',
            id: 'peru_espanhol',
            url: 'https://www.equifax.pe'
          }
        ]
      },
      {
        text: 'Chile',
        languages: [
          {
            label: 'Espanhol',
            id: 'Chile - Espanhol',
            url: 'https://soluciones.equifax.cl/'
          }
        ]
      },
      {
        text: 'Costa Rica',
        languages: [
          {
            label: 'Espanhol',
            id: 'costa-rica_espanhol',
            url: 'https://www.equifax.co.cr'
          }
        ]
      },
      {
        text: 'Equador',
        languages: [
          {
            label: 'Espanhol',
            id: 'equador_espanhol',
            url: 'https://www.equifax.ec'
          }
        ]
      },
      {
        text: 'El Salvador',
        languages: [
          {
            label: 'Espanhol',
            id: 'el-salvador_espanhol',
            url: 'https://www.equifax.sv/'
          }
        ]
      },
      {
        text: 'Honduras',
        languages: [
          {
            label: 'Espanhol',
            id: 'honduras_espanhol',
            url: 'https://www.equifax.hn'
          }
        ]
      },
      {
        text: 'Paraguai',
        languages: [
          {
            label: 'Espanhol',
            id: 'paraguai_espanhol',
            url: 'https://soluciones.equifax.com.py'
          }
        ]
      },
      {
        text: 'Uruguai',
        languages: [
          {
            label: 'Espanhol',
            id: 'uruguai_espanhol',
            url: 'https://www.equifax.uy'
          }
        ]
      }
    ]
  },
  {
    title: 'Europa',
    list: [
      {
        text: 'Portugal',
        languages: [
          {
            label: 'Português',
            id: 'portugal_portugues',
            url: 'https://www.equifax.pt'
          }
        ]
      },
      {
        text: 'Reino Unido',
        languages: [
          {
            label: 'Inglês',
            id: 'reino-unido_ingles',
            url: 'https://www.equifax.co.uk/'
          }
        ]
      },
      {
        text: 'Espanha',
        languages: [
          {
            label: 'Espanhol',
            id: 'espanha_espanhol',
            url: 'https://soluciones.equifax.es'
          }
        ]
      }
    ]
  },
  {
    title: 'Ásia e Oceania',
    list: [
      {
        text: 'Austrália',
        languages: [
          {
            label: 'Inglês',
            id: 'australia_ingles',
            url: 'https://www.equifax.com.au'
          }
        ]
      },
      {
        text: 'Nova Zelândia',
        languages: [
          {
            label: 'Inglês',
            id: 'nova-zelandia_ingles',
            url: 'https://www.equifax.co.nz/'
          }
        ]
      },
      {
        text: 'India',
        languages: [
          {
            label: 'Inglês',
            id: 'india_ingles',
            url: 'https://www.equifax.co.in'
          }
        ]
      }
    ]
  }
]
