'use client'

import { Menu, SubHeader, TopContent, WorldwideSites } from './components'

import { useLPHeader } from './useLPHeader'

import styles from './LPHeader.module.scss'

export const LPHeader = () => {
  const {
    selectedCategory,
    isMenuOpen,
    isWorldwideSitesVisible,
    setSelectedCategory,
    setIsMenuOpen,
    setIsWorldwideSitesVisible,
    closeMenu
  } = useLPHeader()

  return (
    <header className={styles.header}>
      <div className={styles.header__topContent}>
        <TopContent
          isMenuOpen={isMenuOpen}
          selectedCategory={selectedCategory}
          isWorldwideSitesVisible={isWorldwideSitesVisible}
          setIsMenuOpen={setIsMenuOpen}
          setIsWorldwideSitesVisible={setIsWorldwideSitesVisible}
          setSelectedCategory={setSelectedCategory}
        />

        {isMenuOpen && (
          <Menu
            closeMenu={closeMenu}
            setIsWorldwideSitesVisible={setIsWorldwideSitesVisible}
          />
        )}

        {isWorldwideSitesVisible && (
          <WorldwideSites
            setIsWorldwideSitesVisible={setIsWorldwideSitesVisible}
          />
        )}
      </div>

      <SubHeader selectedCategory={selectedCategory} closeMenu={closeMenu} />
    </header>
  )
}
