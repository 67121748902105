import {
  GET_CREDIT_CARD_URL,
  KONDUTO_URL,
  POSITIVE_CONSUMER_URL,
  RIGHT_DEAL_URL
} from '@/constants/links'

const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

export const SECTION_ANCHORS = [
  {
    id: 'businessSolutions',
    label: 'Soluções para Empresas ',
    path: '#solucoes-empresa'
  },
  {
    id: 'personalSolutions',
    label: 'Soluções para você ',
    path: '#solucoes-pessoa'
  },
  { id: 'help', label: 'Ajuda ', path: '#ajuda' }
]

export const ORGANIC_BUSINESS_LINKS = [
  {
    id: 'plans',
    label: 'Planos Pós-Pago',
    path: `${urlEquifaxOrganic}/planos/` // eslint-disable-line no-undef
  },
  {
    id: 'prepaid',
    label: 'Recarga Pré-Pago',
    path: `${urlEquifaxOrganic}/consultas-pre-pago/` // eslint-disable-line no-undef
  },
  {
    id: 'solutions',
    label: 'Soluções',
    path: `${urlEquifaxOrganic}/solucoes-para-empresas/` // eslint-disable-line no-undef
  },
  {
    id: 'antifraud',
    target: '_blank',
    label: 'Antifraude',
    path: KONDUTO_URL
  }
]

export const CONSUMER_LINKS = [
  {
    id: 'score',
    target: '_blank',
    label: 'Monitorar score',
    path: POSITIVE_CONSUMER_URL
  },
  {
    id: 'negotiateDebts',
    target: '_blank',
    label: 'Renegociar Dívidas',
    path: RIGHT_DEAL_URL
  },
  {
    id: 'positiveConsumer',
    target: '_blank',
    label: 'Consultar CPF',
    path: POSITIVE_CONSUMER_URL
  },
  {
    id: 'getLoan',
    target: '_blank',
    label: 'Buscar empréstimo',
    path: GET_CREDIT_CARD_URL
  }
]

export const NAVIGATION_LINKS = isOrganic
  ? ORGANIC_BUSINESS_LINKS
  : SECTION_ANCHORS
