import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { useScreenSize } from '@/hooks/useScreenSize'

import { Container } from '@/components'

import { WorldwideSitesToggler } from '..'

import { ExpandableCategory } from './components'

import { WORLDWIDE_SITES } from './constants'

import styles from './WorldwideSites.module.scss'

export const WorldwideSites = ({ setIsWorldwideSitesVisible }) => {
  const { windowHeight } = useScreenSize()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <div
      style={{
        height: isMobile ? windowHeight : null
      }}
      className={styles.worldwideWebsites}
    >
      {isMobile && (
        <WorldwideSitesToggler
          color="white"
          arrowPosition="left"
          customClass={styles.worldwideWebsites__toggler}
          onClick={() => setIsWorldwideSitesVisible((prev) => !prev)}
        />
      )}

      <Container>
        <div className={styles.worldwideWebsites__list}>
          {WORLDWIDE_SITES.map(({ title, list }) => (
            <ExpandableCategory
              list={list}
              title={title}
              key={`worldwideWebsites-${title}`}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}
