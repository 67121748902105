import { useEffect, useState } from 'react'

import classnames from 'classnames'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Icon } from '@/components'

import styles from './ExpandableCategory.module.scss'

export const ExpandableCategory = ({ title, list }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isDesktop = useMediaQuery('screen and (min-width: 1024px)')

  useEffect(() => {
    setIsOpen(isDesktop)
  }, [isDesktop])

  return (
    <div>
      <button
        id={`header-category-${title}`}
        className={styles.expandableCategory}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.expandableCategory__titleArea}>
          <Icon name="equifax-world" color="primary" size="small" />
          <p>{title}</p>
        </div>

        {!isDesktop && (
          <Icon
            name="arrow-down"
            size="small"
            className={classnames(styles.expandableCategory__arrowDown, {
              [styles['expandableCategory__arrowDown--up']]: isOpen
            })}
          />
        )}
      </button>

      <ul
        className={classnames(styles.expandableCategory__list, {
          [styles['expandableCategory__list--open']]: isOpen
        })}
      >
        {list.map(({ text, languages }, index) => (
          <li
            key={`header-category-${text}--${index}`}
            className={styles.expandableCategory__item}
          >
            <span className={styles.expandableCategory__content}>{text}</span>

            {languages && (
              <div className={styles.expandableCategory__languages}>
                {languages.map(({ id, label, url }) => (
                  <a
                    id={id}
                    key={id}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={`${text} - ${label}`}
                    className={styles.expandableCategory__language}
                  >
                    {label}
                  </a>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
