import { SkeletonLoader } from '@/components'

import { useNavigationLinks } from './useNavigationLinks'

import styles from './NavigationLinks.module.scss'

export const NavigationLinks = ({ category, variant, onClick }) => {
  const { headerNavigationLinks } = useNavigationLinks()

  return (
    <>
      {!headerNavigationLinks && <SkeletonLoader.Single height={24} />}

      {headerNavigationLinks && (
        <nav className={styles[`navigation--${variant}`]}>
          {headerNavigationLinks[category].map(
            ({ id, label, path, target }) => (
              <a
                rel="noreferrer"
                href={path}
                target={target}
                id={`${variant}-${id}-link`}
                key={`${variant}-${id}-link`}
                onClick={onClick}
                className={styles.navigation__link}
              >
                {label}
              </a>
            )
          )}
        </nav>
      )}
    </>
  )
}
