import styles from './Categories.module.scss'

export const Categories = ({ activeCategory, onClick }) => {
  return (
    <div className={styles.categories}>
      <button
        id="header-businessSelector-button"
        className={`
          ${styles.categories__category} 
          ${activeCategory === 'business' && styles['categories__category--active']}
        ]`}
        onClick={() => onClick('business')}
      >
        <span>Para sua empresa</span>
      </button>

      <button
        id="header-consumerSelector-button"
        className={`
          ${styles.categories__category} 
          ${activeCategory === 'consumer' && styles['categories__category--active']}
        `}
        onClick={() => onClick('consumer')}
      >
        <span>Para você</span>
      </button>
    </div>
  )
}
